/* Green Credits.css */
body {
  /* background-image: url('../assets/images/CardBg.jpg'); Replace with a URL to a relevant background image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9); /* Slight transparency for overlay effect */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

h1 {
  color: #2c3e50;
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 20px;
}

p {
  color: #34495e;
  line-height: 1.6;
  margin-bottom: 15px;
  font-size: 1.1em;
}

.exit-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #e74c3c;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.exit-button:hover {
  background-color: #c0392b;
}
