/* login.css */

/* login.css */

.transparent-box {
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
    padding: 20px;
    border-radius: 10px;
  }

  .form-container {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .text-field {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 4px;
  }

  .action-button {
    margin-top: 1rem;
  }
  .transparent-box .otp-input {
    margin-bottom: 1rem;
  }
  
  .transparent-box .otp-input .MuiInputBase-input {
    color: rgb(250, 250, 250);
  }
  
  .transparent-box .otp-input .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  
  .transparent-box .otp-input .MuiInputLabel-root {
    color: white;
  }

  .signin-dashboard-container {
    /* background: rgba(255, 255, 255, 0.5); */
    background:  rgba(8, 8, 8, 0.5);
    border-radius: 10px;
    padding: 10px;
    max-width: 600px;
    margin: 20px auto 0 auto;
  }

  /* .druthzuci {
    max-width: 200%;
  } */

  /* .dz{
    background:  rgba(243, 240, 240, 0.5);
  }
   */