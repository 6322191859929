/* src/AboutUs.css */

.about-us-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../assets/images/abg2.jpg') no-repeat center center/cover;
    color: #fcfcfc; /* Default text color */
    text-align: center;
}

.content {
    background: rgba(0, 0, 0, 0.6); /* Dark overlay for better readability */
    padding: 20px;
    border-radius: 10px;
    max-width: 80%; /* Ensure the content doesn’t get too wide */
}

.title {
    font-size: 3em;
    margin-bottom: 0.5em;
    color: #e0e0e0; /* Slightly lighter title color */
}

.subtitle {
    font-size: 2em;
    margin-bottom: 1em;
    color: #b0b0b0; /* Slightly lighter subtitle color */
}

.text {
    font-size: 1.2em;
    color: #ffffff; /* White color for paragraph text */
    line-height: 1.6;
}