/* .scrolling-alert {
    background-color: yellow;
    color: black;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .scrolling-alert::before {
    content: 'Mark only in the zone';
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    animation: scroll 10s linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
   */
   /* .scrolling-alert {
    background-color: rgb(240, 240, 238);
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  } */

  .bullet-list {
    list-style-type: disc; /* You can change to 'circle' or 'square' if you prefer */
    padding-left: 20px; /* Adjust as needed */
    font-size: 16px;
  }
  .bullet-list li {
    color: rgb(255, 255, 255); /* Change the color as needed */
  }
  
  